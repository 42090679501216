import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import heart from "../../images/heart.svg";
import save from "../../images/save.png";
import download from "../../images/download.svg";
import { LOCAL_BACKEND, STAGGING_BACKEND } from "../../common/helper";
import TextTruncate from "react-text-truncate";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/pagination/pagination";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";

const ClinicalTrials = ({
  currentPage,
  setCurrentPage,
  render,
  setRender,
  totalPages,
  setTotalPages,
  totalRecords,
  setTotalRecords,
  data,
  loading,
  setLoading,
}) => {
  // const [data, setData] = useState([])

  const navigate = useNavigate();

  // Ref to track the initial mount

  const navigateRoute = (nct_id) => {
    navigate("/results", { state: { nct_id: nct_id } });
  };

  const urlParams = new URLSearchParams(window.location.search);
  const savedFilter = urlParams.get("checkByCustomNctIds");

  const NctIds = JSON.parse(
    window.sessionStorage.getItem("checkByCustomNctIds")
  );
  console.log(NctIds);

  const HandleDownload = async () => {
    var url = null;
    savedFilter != "true"
      ? (url = STAGGING_BACKEND + "cancer/stats/?export=xls")
      : (url =
          STAGGING_BACKEND +
          "cancer/stats/?export=xls&by_save=" +
          NctIds.saved +
          "&custom_nct_ids=" +
          NctIds.nct_ids);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      responseType: "blob",
      // body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.blob();

    const blob = new Blob([responseData]);
    const blob_url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blob_url;
    link.setAttribute("download", "clinical_trials.xls"); // Set the desired file name

    // Append the link to the document body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the URL object
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const HandleSave = async () => {
    var url = null;

    savedFilter != "true"
      ? (url = STAGGING_BACKEND + "cancer/stats/?export=save")
      : (url =
          STAGGING_BACKEND +
          "cancer/stats/?export=save&by_save=" +
          NctIds.saved +
          "&custom_nct_ids=" +
          NctIds.nct_ids);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      responseType: "blob",
      // body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    console.log(responseData.data);

    setRender(!render);
  };

  const HandleSingleDownload = async (nct_id) => {
    var url = null;

    savedFilter != "true"
      ? (url = STAGGING_BACKEND + "cancer/trialdetails/")
      : (url = STAGGING_BACKEND + "cancer/trialdetails/");

    const payload = {
      nct_id: nct_id,
      export: "xls",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.blob();

    const blob = new Blob([responseData]);
    const blob_url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blob_url;
    link.setAttribute("download", `trial-${nct_id}.xls`); // Set the desired file name

    // Append the link to the document body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the URL object
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const HandleSingleSave = async (nct_id) => {
    var url = null;
    savedFilter != "true"
      ? (url = STAGGING_BACKEND + "cancer/trialdetails/")
      : (url = STAGGING_BACKEND + "cancer/trialdetails/");

    const payload = {
      nct_id: nct_id,
      export: "save",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    console.log(responseData.data);
    setRender(!render);
  };

  const HandleUnSave = async (nct_id) => {
    const url = STAGGING_BACKEND + "cancer/unsave/";

    const payload = {
      nct_id: nct_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    console.log(responseData.data);
    setRender(!render);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="d-flex justify-content-end mb-3">
        <button
          className="blue-button"
          style={{ marginRight: "10px" }}
          onClick={() => {
            HandleSave();
          }}
        >
          Save All
        </button>
        <button
          className="blue-button"
          onClick={() => {
            HandleDownload();
          }}
        >
          Download
        </button>
      </div>
      <div
        style={{ overflowX: "auto", overflowY: "auto", maxHeight: "370px" }}
        className="drugstatusTable-wrapper"
      >
        <Table hover className="drugsTable">
          <thead>
            <tr className="w-100">
              {/* <th>RANK</th>
              <th>SCORE</th> */}
              <th>NCT ID</th>
              <th>DISTANCE</th>
              <th>PHASE</th>
              <th>STATUS</th>
              <th>BRIEF TITLE</th>
              <th>TRIAL DETAILS</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, key) => {
              return (
                <tr>
                  {/* <td></td>
                <td className="drugName"><p>{item.score}</p></td> */}
                  <td>
                    <p>{item.nct_id}</p>
                  </td>
                  <td>{item.distance}</td>
                  <td>{item.phase}</td>
                  <td>
                    <p>{item.status}</p>
                  </td>
                  <td
                   
                  >
                    {/* {item.brief_title} */}
                    <CustomTooltip text={item.brief_title} />

                  </td>
                  <td>
                    <div style={{ minWidth: "230px" }}>
                      {/* <Link to='/results'> */}
                      <button
                        className="blue-button"
                        onClick={() => {
                          navigateRoute(item.nct_id);
                        }}
                      >
                        See Details
                      </button>
                      {/* </Link> */}
                      {item.saved ? (
                        <img
                          src={save}
                          className="drugstableIcon"
                          alt=""
                          onClick={() => {
                            HandleUnSave(item.nct_id);
                          }}
                        />
                      ) : (
                        <img
                          src={heart}
                          className="drugstableIcon"
                          alt=""
                          onClick={() => {
                            HandleSingleSave(item.nct_id);
                          }}
                        />
                      )}
                      <img
                        src={download}
                        className="drugstableIcon"
                        alt=""
                        onClick={() => {
                          HandleSingleDownload(item.nct_id);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        totalRecords={totalRecords}
      />
    </>
  );
};

export default ClinicalTrials;
