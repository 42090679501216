import React from "react";
import styles from "../../css/customTooltip.module.css";

function CustomTooltip({ text }) {
  return (
    <td className={styles.tooltipContainer}>
      <p
        style={{
          whiteSpace: "nowrap", // Keeps the text on one line
          overflow: "hidden", // Hides text that goes beyond the cell width
          textOverflow: "ellipsis", // Adds an ellipsis (...) at the point where the text is cut off
        }}
      >
        {text}
      </p>
      <div className={styles.tooltip}>{text}</div>
    </td>
  );
}

export default CustomTooltip;
