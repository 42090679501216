import React, { useState, useEffect, useCallback, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { storeOptions } from "../../redux/slices/clinicalOptions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { object } from "yup";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import SaveData from "../../components/Save_Data/Save_Data";
import RenderData from "../../components/Save_Data/Render_Data";
import DeleteData from "../../components/Save_Data/Delete_Data";
import EmptyFields from "../../components/Save_Data/Empty_Fields";
// import { Prev } from "react-bootstrap/esm/PageItem";
import QuestionAirFormFooter from "../../components/questionAirFormFooter/questionAirFormFooter";
import QuestionAirTabs from "../../components/questionairTabs/questionairTabs";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import Select , {createFilter} from "react-select";
import logo from "../../images/logo.png";
import {

  questionAirOptionCards,
} from "../../libs/optionsHandling";
import { generateNamesArray, navigateBasedOnNextItem } from "../../libs/Util";
import { postToApi, getFromApi } from "../../libs/baseApi";
import { getQueryParam } from "../../libs/Util";
import { useLocation } from "react-router-dom";
import { tab1QuestionsResponse } from "../../libs/questionairResponse";
import Loader from "../../components/Loader/Loader"
import Sidebar from "../../components/sidebar/Sidebar";
import Header from "../../components/Header/Header";

const useForceUpdate = () => {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
};

function ClinicalQuestions() {
  const [data, setData] = useState([]);
  const [subtypeData, setSubtypeData] = useState([]);
  const [subtypeBData, setSubtypeBData] = useState([]);
  const [subtypeCData, setSubtypeCData] = useState([]);
  const [subtypeDData, setSubtypeDData] = useState([]);
  const [cancerType, setCancerType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cancerTypeOptions, setCancerTypeOptions] = useState([
    { value: "", label: "choose below" },
  ]);
  const [subtypeDataOptions, setSubtypeDataOptions] = useState([
    { value: "", label: "choose below" },
  ]);
  const [subtypeBDataOptions, setSubtypeBDataOptions] = useState([
    { value: "", label: "choose below" },
  ]);
  const [subtypeCDataOptions, setSubtypeCDataOptions] = useState([
    { value: "", label: "choose below" },
  ]);
  const [subtypeDDataOptions, setSubtypeDDataOptions] = useState([
    { value: "", label: "choose below" },
  ]);

  const [stageOptions, setStageOptions] = useState([
    { value: "", label: "choose below" },
  ]);
  const location = useLocation();
  // const optionsToShow = getQueryParam("optionsToShow", location.search);

  const [selectedElements, setSelectedElements] = useState([]);
  const [importDataState, setImportDataState] = useState(false);
  const [saveDataState, setSaveDataState] = useState(false);
  const [tumorType, setTumorType] = useState();
  const [subtype, setSubType] = useState("");
  const [subtypeB, setSubTypeB] = useState("");
  const [subtypeC, setSubTypeC] = useState("");
  const [subtypeD, setSubTypeD] = useState("");
  const [stage, setStage] = useState("");
  const [sideBar, setSideBar] = useState(false);
  const [header, setHeader] = useState(false);

  const [clearData, setClearData] = useState(false);
  const navigate = useNavigate();
  const update = useForceUpdate();
  const selector = useSelector((state) => state.options);
  const nullValue = { label: "choose below", value: "" };


  const handleEvent4 = async (item, flag) => {
    const endpoint = "cancer/create/";
    const payload = {
      filter: "tumor_subtypes",
      cancertype: item,
    };
    try {
      const responseData = await postToApi(endpoint, payload);
      setSubtypeDData(responseData.data);
      if (responseData.data) {
        const formattedOptions = [{ value: "", label: "choose below" }].concat(
          responseData.data.map((item) => ({
            value: item,
            label: item,
          }))
        );
        setSubtypeDDataOptions(formattedOptions);
        if (flag === "impData") {
          Import_Save_Data("Cancer_Type_subtypeD", formattedOptions);
        } else {
          setSubTypeD(nullValue);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEvent3 = async (item, flag) => {
    const endpoint = "cancer/create/";
    const payload = {
      filter: "tumor_subtypes",
      cancertype: item,
    };
    try {
      const responseData = await postToApi(endpoint, payload);
      setSubtypeCData(responseData.data);
      if (responseData.data) {
        const formattedOptions = [{ value: "", label: "choose below" }].concat(
          responseData.data.map((item) => ({
            value: item,
            label: item,
          }))
        );
        setSubtypeCDataOptions(formattedOptions);
        if (flag === "impData") {
          Import_Save_Data("Cancer_Type_subtypeC", formattedOptions);
        } else {
          setSubTypeC(nullValue);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEvent2 = async (item, flag) => {
    const url = STAGGING_BACKEND + "cancer/create/";
    const payload = {
      filter: "tumor_subtypes",
      cancertype:
        item || window.localStorage.getItem(`Cancer_Type_what_cancer`),
    };
    // setCancerType(item);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();

    setSubtypeBData(responseData.data);
    if (responseData.data) {
      const formattedOptions = [{ value: "", label: "choose below" }].concat(
        responseData.data.map((item) => ({
          value: item,
          label: item,
        }))
      );
      setSubtypeBDataOptions(formattedOptions);

      if (flag == "impData") {
        Import_Save_Data("Cancer_Type_subtype2", formattedOptions);
      } else {
        setSubTypeB(nullValue);
      }
    }
  };


  
  

  const handleEvent1 = async (item, flag) => {
    const url = STAGGING_BACKEND + "cancer/create/";
    const payload = {
      filter: "tumor_subtypes",
      cancertype: item,
    };
    setCancerType(item);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    setSubtypeData(responseData.data);

    if (responseData.data) {
      const formattedOptions = [{ value: "", label: "choose below" }].concat(
        responseData.data.map((item) => ({
          value: item,
          label: item,
        }))
      );
      setSubtypeDataOptions(formattedOptions);
      setSubtypeCData([]);
      setSubtypeBData([]);

      if (document.querySelector("#stagDropdown"))
        document.querySelector("#stagDropdown").value = "";
      if (document.querySelector("#subtype"))
        document.querySelector("#subtype").value = "";
      if (flag == "impData") {
        Import_Save_Data("Cancer_Type_subtype", formattedOptions);
      } else {
        setSubType(nullValue);
      }
    }
  };

  const fetchStages = async (value) => {
    console.log('the valueeee', value)
    const url = STAGGING_BACKEND + "cancer/questionair/tumor_type_stage/";

    const payload = {
      tumor_type: value,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    const formattedOptions = [{ value: "", label: "choose below" }].concat(
      responseData.data.map((item) => ({
        value: item,
        label: item,
      }))
    );

    // setStage("");

    setStageOptions(formattedOptions);
    const storeStage = localStorage.getItem('Cancer_Type_stagDropdown');
    const stageSet = storeStage ?  setStage({ label: storeStage, value: storeStage }) : setStage({ value: "", label: "choose below" })
  };

  useEffect(() => {
    async function fetchData() {
      const url = STAGGING_BACKEND + "cancer/create/";

      const payload = {
        filter: "all_tumor_types",
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(url, requestOptions);
      const responseData = await response.json();


      const formattedOptions = [{ value: "", label: "choose below" }].concat(
        responseData.data.map((item) => ({
          value: item,
          label: item,
        }))
      );

      setCancerTypeOptions(formattedOptions);
      setData(responseData.data);
      setImportDataState(true);

      let select_array = [
        { title: "Cancer_Type_what_cancer", options: formattedOptions },
        { title: "Cancer_Type_subtype", options: subtypeDataOptions },
      ];
      Import_Save_Data("Cancer_Type_what_cancer", formattedOptions);
      Import_Save_Data("Cancer_Type_stagDropdown", stageOptions);



    }
    fetchData();
  }, []);

  function Import_Save_Data(title, options) {
    const genderOpt = window.localStorage.getItem(title);

    if (genderOpt) {
      const result = options.find((option) => option.value == genderOpt);
      if (result) {
        switch (title) {
          case "Cancer_Type_what_cancer":
            setTumorType(result);
            console.log('result', result);
            fetchStages(result.value);
            handleEvent1(result.value, "impData");
            break;
          case "Cancer_Type_subtype":
            setSubType(result);
            handleEvent2(result.value, "impData");
            break;
          case "Cancer_Type_subtype2":
            setSubTypeB(result);
            handleEvent3(result.value, "impData");
            break;
          case "Cancer_Type_subtypeC":
            setSubTypeC(result);
            break;
          case "Cancer_Type_subtypeD":
            setSubTypeD(result);
            break;
          case "Cancer_Type_stagDropdown":
            setStage(result);
            break;
          default:
            break;
        }
      }
    }
  }

  useEffect(() => {
    setSaveDataState(true);
    Save_Select_Data();
  }, [tumorType, subtype, subtypeB, subtypeC, stage]);

  function Save_Select_Data() {
    if (tumorType)
      window.localStorage.setItem(`Cancer_Type_what_cancer`, tumorType.value);
    if (subtype)
      window.localStorage.setItem(`Cancer_Type_subtype`, subtype.value);
    if (subtypeB)
      window.localStorage.setItem(`Cancer_Type_subtype2`, subtypeB.value);
    if (subtypeC)
      window.localStorage.setItem(`Cancer_Type_subtypeC`, subtypeC.value);
    if (subtypeD)
      window.localStorage.setItem(`Cancer_Type_subtypeD`, subtypeD.value);
    if (stage)
      window.localStorage.setItem(`Cancer_Type_stagDropdown`, stage.value);
  }

  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get("page");
  const mode = urlParams.get("mode");
  const savedFilter = urlParams.get("checkByCustomNctIds");
  const optionsToShow = new URLSearchParams(location.search).get(
    "optionsToShow"
  );
  const indicesToShow = optionsToShow
    ? optionsToShow.split(",").map(Number)
    : [];

  const pageOptions = indicesToShow
    .map((index) => {
      const card = questionAirOptionCards.find((card) => card.id === index);
      return card ? card.name.replace(/ & /g, "_").replace(/ /g, "_") : null; 
    })
    .filter((name) => name !== null);  

  let names = generateNamesArray(mode, pageOptions, savedFilter, page, optionsToShow);

  const OnSubmitForm = async () => {
    setIsLoading(true);
    setSaveDataState(true);
    const endpoint = `cancer/questionair/cancer_type/?instance=${true}`;
    const payload = {
      tumor_type: tumorType?.value,
      subtype: subtype?.value,
      subtypeB: subtypeB?.value,
      subtypeC: subtypeC?.value,
      stage: stage?.value,
    };
    try {
        await postToApi(endpoint, payload);
      if (mode !== "full_options") {
        const indicesToShow = optionsToShow.split(",").map((str) => str);
        console.log("indicesToShow", indicesToShow);
        const currentIndexPosition = indicesToShow.indexOf("0");
        let nextIndex = null;  
        if (
          currentIndexPosition !== -1 &&
          currentIndexPosition < indicesToShow.length - 1
        ) {
          nextIndex = indicesToShow[currentIndexPosition + 1];
        }
        console.log("Next Index:", nextIndex);
        if (nextIndex !== null) {
          const nextOptionCard = questionAirOptionCards.find(
            (card) => card.id == nextIndex
          );
          navigate(`/${nextOptionCard.page}?mode=null&optionsToShow=${optionsToShow}`);
        }
      } else {
        navigate(
          `/clinical-question2?mode=full_options${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
    finally {
        setIsLoading(false);
    }
  };


  function Sort_Array(array) {
    const itemToFind = "Cancer_Type_&_Stage";
    const foundIdx = array.findIndex((el) => el == itemToFind); // -> foundIdx = 3
    array.splice(foundIdx, 1);
    array.unshift(itemToFind);
  }

  const OnSubmitForm2 = async () => {
    const endpoint = `cancer/questionair/cancer_type/?instance=${true}`;
    setIsLoading(true);
    const payload = {
      tumor_type: tumorType?.value,
      subtype: subtype?.value,
      subtypeB: subtypeB?.value,
      subtypeC: subtypeC?.value,
      subtypeD: subtypeD?.value,
      stage: stage?.value,
    };

    try {
      const responseData = await postToApi(endpoint, payload);
      navigate(
        page === "ccTreatmentGuide"
          ? "/cc-treatment-plans"
          : `/stats-drugs${savedFilter === "true" ? "?checkByCustomNctIds=true" : ""
          }`
      );
    } catch (error) {
      console.error("Error:", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleClearCancel = () => {
    setClearData(false);
  };
  const handleClearConfirm = () => {
    setClearData(false);
    setTumorType(nullValue);
    setSubType(nullValue);
    setSubTypeB(nullValue);
    setSubTypeC(nullValue);
    setSubTypeD(nullValue);
    setStage(nullValue);
    setSubtypeCData([]);
    setSubtypeBData([]);
    setSubtypeData([]);
    setSubtypeDData([]);
  };

  useEffect(() => {
    console.log('Stage Options:', stageOptions);
    console.log('Selected Stage:', stage);
  }, [stageOptions, stage]);
  function handleHeader() {
    setHeader((t) => !t);
  }

  function handleSideBar() {
    setSideBar((t) => !t);
  }

  const customFilter = createFilter({
    matchFrom: 'start',
    stringify: option => option.label.split(' ')[0],
  });

  return (
    <>
      {
        isLoading && <Loader />
      } 
      <div className="mobile-header-section">
          <div
            id="nav-icon4"
            className={!sideBar ? "hamburger" : "hamburger open"}
            onClick={handleSideBar}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src={logo} style={{ height: "60px" }} alt="" />
          <div
            id="nav-icon3"
            onClick={handleHeader}
            className={!header ? "hamburger" : "hamburger open"}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="signup-section-wrapper">
          <Sidebar sideBar={sideBar} />

          <div className="signup-inner-parent">
            <Header header={header} />

        <div className="clinical-question-main-area mt-4">
          <h1 className="text-center site-heading">
            {page === "ccTreatmentGuide"
              ? "CC Treatment Guide"
              : "Clinical Trial Finder"}
          </h1>
          <QuestionAirTabs names={names} currentTab="0" />
          <div className="activeSection-wrapper">
            <div className="question-form-wrapper">
              <form action="">
                <h2>Cancer Type & Stage</h2>
                <p>Please input the following data</p>
                <div className="form_container_sec">
                  <label htmlFor="">What cancer are you interested in? </label>
                  <Select
                    options={cancerTypeOptions}
                    value={tumorType}
                    className="select_search"
                    id="what_cancer"
                    onChange={(event) => {
                      handleEvent1(event.value);
                      setTumorType(event);
                      fetchStages(event.value);
                      // setSubType(nullValue);
                     
                    }}
                    styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 1000 
                          })
                        }}
                    filterOption={customFilter}
                  />
                  {subtypeData?.length > 0 && (
                    <>
                      <label htmlFor="">What is Tumor Type Level 2? </label>

                      <Select
                        options={subtypeDataOptions}
                        value={subtype}
                        className="select_search"
                        id="subtype"
                        onChange={(event) => {
                          handleEvent2(event.value);
                          setSubType(event);
                          fetchStages(event.value);
                        }}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 1000 
                          })
                        }}
                      />
                    </>
                  )}

                  {subtypeBData?.length > 0 && (
                    <>
                      <label htmlFor="">What is Tumor Type Level 3?</label>
                      <Select
                        options={subtypeBDataOptions}
                        value={subtypeB}
                        className="select_search"
                        id="subtype2"
                        onChange={(event) => {
                          handleEvent3(event.value);
                          setSubTypeB(event);
                          fetchStages(event.value);
                        }}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 1000 
                          })
                        }}
                      />
                    </>
                  )}

                  {subtypeCData?.length > 0 && (
                    <>
                      <label htmlFor="">What is Tumor Type Level 4? </label>

                      <Select
                        options={subtypeCDataOptions}
                        value={subtypeC}
                        className="select_search"
                        id="subtypeC"
                        onChange={(event) => {
                          handleEvent4(event.value)
                          setSubTypeC(event);
                          fetchStages(event.value);
                        }}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 1000 
                          })
                        }}
                      />
                    </>
                  )}

                  {subtypeDData?.length > 0 && (
                    <>
                      <label htmlFor="">What is Tumor Type Level 5? </label>

                      <Select
                        options={subtypeDDataOptions}
                        value={subtypeD}
                        className="select_search"
                        id="subtypeD"
                        onChange={(event) => {
                          setSubTypeD(event);
                          fetchStages(event.value);
                        }}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 1000 
                          })
                        }}
                      />
                    </>
                  )}


                  {tumorType?.value && (
                    <>
                      <label htmlFor="">What stage is your cancer? </label>

                      <Select
                        options={stageOptions}
                        value={stage}
                        className="select_search"
                        id="stagDropdown"
                        onChange={(event) => {
                          setStage(event);
                        }}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 1000 
                          })
                        }}
                    
                      />
                    </>
                  )}
                </div>
                <QuestionAirFormFooter
                  handleSubmit={OnSubmitForm}
                  handleSubmit2={OnSubmitForm2}
                  handleClear={() => setClearData(true)}
                />
              </form>
            </div>
          </div>
        </div>
         </div>
         </div>               

      {saveDataState && (
        <SaveData
          feilds={["subtype", "subtype2", "subtypeC", "subtypeD", "stagDropdown"]}
          title={"Cancer_Type"}
        />
      )}
      {importDataState && (
        <RenderData
          feilds={["subtype", "subtype2", "subtypeC", "subtypeD", "stagDropdown"]}
          title={"Cancer_Type"}
        />
      )}
      {
        clearData && (
          <DeleteData
            onCancel={handleClearCancel}
            onConfirm={handleClearConfirm}
            feilds={[
              "what_cancer", "subtype", "subtype2", "subtypeC", "subtypeD"
            ]}
          />
        )
      }

    </>
  );
}

export default ClinicalQuestions;