import React, { useState, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import SaveData from "../../components/Save_Data/Save_Data";
import RenderData from "../../components/Save_Data/Render_Data";
import DeleteData from "../../components/Save_Data/Delete_Data";
import QuestionAirFormFooter from "../../components/questionAirFormFooter/questionAirFormFooter";
import QuestionAirTabs from "../../components/questionairTabs/questionairTabs";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { generateNamesArray, handleSwitchStatement  } from "../../libs/Util";
import { toptions, pregnantOptions, breastFeedingOptions, menopausalOptions, raceOptions } from "../../libs/optionsHandling";
import { useLocation } from "react-router-dom"; 
import { navigateToPreviousOption, navigateToNextOption, getPageOptionsFromIndices } from "../../libs/Util";
import Loader from "../../components/Loader/Loader";


function ClinicalQuestion2() {
  const location = useLocation();
  const [lastOption, setLastOption] = useState(false);
  const [showdropDown, setShowdropDown] = useState(false);
  const [showdropDown2, setShowdropDown2] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [saveDataState, setSaveDataState] = useState(false);
  const [importDataState, setImportDataState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clearData, setClearData] = useState(false);
  const [gender, setGender] = useState("");
  const [age, setAge] = useState(null);
  const [race, setRace] = useState("");
  const [isMenopausal, setIsMenopausal] = useState(null);
  const [isPregnant, setIsPregnant] = useState(null);
  const [isBreastfeeding, setIsBreastfeeding] = useState(null);
  const navigate = useNavigate();
  const selector = useSelector((state) => state.options);
  const nullValue = { label: "choose below", value: "" };
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("mode");
  const page = urlParams.get("page");
  const mode = urlParams.get("mode");
  const savedFilter = urlParams.get("checkByCustomNctIds");
  const ageRef = useRef();

  const optionsToShow = new URLSearchParams(location.search).get(
    "optionsToShow"
  );
  const pageOptions = getPageOptionsFromIndices(optionsToShow);
  let names = generateNamesArray(mode, pageOptions, savedFilter, page, optionsToShow);

  const OnSubmitForm = async () => {
    const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
    setIsLoading(true)
    try {
      const payload = {
        gender: gender.value,
        age: ageRef.current.value ? ageRef?.current?.value : null,
        race: race.value,
        is_pregnant: isPregnant?.value,
        is_breastfeeding: isBreastfeeding?.value,
        menopausal: isMenopausal?.value,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();
      if (mode !== "full_options") {
        navigateToNextOption(navigate, '1', mode, optionsToShow);
      } else {
        console.log("----", savedFilter);
        navigate(
          `/clinical-question3?mode=full_options${
            page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
      }   
    } catch (error) {
      console.log('error', error)
    }finally {
      setIsLoading(false)
    }
   
  };
  
  useEffect(() => {
    let array = names.map((item) => {
      return item.text;
    });
    Check_Last_Index(array);
    setImportDataState(true);
    let select_array = [
      { title: "Demographics_race", options: raceOptions },
      { title: "Demographics_gender", options: toptions },
      { title: "Demographics_pregnant", options: pregnantOptions },
      { title: "Demographics_breastfeeding", options: breastFeedingOptions },
      { title: "Demographics_menopausal", options: menopausalOptions },
    ];
    select_array.forEach((item) => {
      Import_Save_Data(item.title, item.options);
    });
  }, []);

  function Import_Save_Data(title, options) {
    const genderOpt = window.localStorage.getItem(title);
    const result = options.find((option) => option.value === genderOpt);
    if (result) {
      switch (title) {
        case "Demographics_race":
          setRace(result);
          break;
        case "Demographics_gender":
          setGender(result);
          break;
          case "Demographics_pregnant":
          setIsPregnant(result);
          break;
          case "Demographics_breastfeeding":
          setIsBreastfeeding(result);
          break;
          case "Demographics_menopausal":
          setIsMenopausal(result);
          break;
         
        default:
          break;
      }
    }
  }

  useEffect(() => {
    setSaveDataState(true);
    Save_Select_Data();
  }, [age, gender, race, isPregnant , isBreastfeeding, isMenopausal]);

  useEffect(()=>{
    if(clearData == true)
    {
      setGender(nullValue);
      setRace(nullValue)
    }
  },[clearData])

  function Save_Select_Data() {
    if (gender)
      window.localStorage.setItem(`Demographics_gender`, gender.value);
    if (race) window.localStorage.setItem(`Demographics_race`, race.value);
    if (isPregnant) window.localStorage.setItem(`Demographics_pregnant`, isPregnant.value);
    if (isBreastfeeding) window.localStorage.setItem(`Demographics_breastfeeding`, isBreastfeeding.value);
    if (isMenopausal) window.localStorage.setItem(`Demographics_menopausal`, isMenopausal.value);
  }

  function Check_Last_Index(array) {
    const lastIndex = array[array.length - 1];
    if (lastIndex == "Demographics") setLastOption(true);
  }

  const OnSubmitForm2 = async () => {
   try {
    const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
    setIsLoading(true);
    const payload = {
      gender: gender.value,
      age: ageRef.current.value ? ageRef?.current?.value : null,
      race: race.value,
      is_pregnant: isPregnant?.value,
      is_breastfeeding: isBreastfeeding?.value,
      menopausal: isMenopausal?.value,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "token " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    };
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    navigate(
      page === "ccTreatmentGuide"
        ? "/cc-treatment-plans"
        : `/stats-drugs${
            savedFilter === "true" ? "?checkByCustomNctIds=true" : ""
          }`
    );
   } catch (error) {
    console.log('error', error)
   } finally {
    setIsLoading(false);
   }
  };

  function HandleBackBtn() {
    if (mode !== "full_options") {
      navigateToPreviousOption(navigate, '1', mode, optionsToShow);
    } else {
      navigate(
        `/clinical-questions?mode=full_options${
          page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
      );
    }
  }
  

  const handleClearCancel = () => {
    setClearData(false);
  };

  const handleClearConfirm = () => {
    setGender(nullValue);
    setRace(nullValue);
    setClearData(false);
    setIsBreastfeeding(nullValue);
    setIsPregnant(nullValue);
    setIsMenopausal(nullValue);
  };

  return (
    <>
    {
      isLoading && <Loader/>
    }
      <DashboardLayout>
        <h1 className="text-center site-heading">
          {page === "ccTreatmentGuide"
            ? "CC Treatment Guide"
            : "Clinical Trial Finder"}
        </h1>
        <QuestionAirTabs names={names} currentTab="1" />

        <div className="activeSection-wrapper">
          <div className="question-form-wrapper">
            <form action="">
              <h2>Patient Characteristics</h2>
              <p>Please input the following data</p>

              <div className="form_container_sec">
                <label htmlFor="">What gender do you identify with? </label>
                <Select
                  options={toptions}
                  value={gender}
                  className="select_search"
                  id="gender"
                  onChange={(event) => {
                    setGender(event);
                  }}
                />

                {gender.value === "Female" && (
                  <>
                    <label htmlFor="">Are you pregnant? </label>
                    <Select
                      options={pregnantOptions}
                      value={isPregnant}
                      className="select_search"
                      id="pregnant"
                      name="pregnant"
                      onChange={(event) => {
                        setIsPregnant(event);
                      }}
                    />

                    <label htmlFor="">Are You breastfeeding? </label>
                    <Select
                      options={breastFeedingOptions}
                      value={isBreastfeeding}
                      className="select_search"
                      id="breastfeeding"
                      name="breastfeeding"
                      onChange={(event) => {
                        setIsBreastfeeding(event);
                      }}
                    />

                    <label htmlFor="">What is your menopausal status? </label>
                    <Select
                      options={menopausalOptions}
                      value={isMenopausal}
                      className="select_search"
                      id="menopausal"
                      name="menopausal"
                      onChange={(event) => {
                        setIsMenopausal(event);
                      }}
                    />
                  </>
                )}

                <label htmlFor="">Enter your DOB. </label>
                <input
                  type="date"
                  name="userName"
                  className="signup-box-input loginfields"
                  placeholder="Enter your age"
                  id="age"
                  ref={ageRef}
                  onChange={(event) => setAge(event.target.value)}
                />

                <label htmlFor="">What race do you identify with? </label>
                <Select
                  options={raceOptions}
                  value={race}
                  className="select_search"
                  id="race"
                  onChange={(event) => {
                    setRace(event);
                  }}
                  styles={{
                    menu: (base) => ({
                      ...base,
                      zIndex: 1000 
                    })
                  }}
                />
              </div>
              <QuestionAirFormFooter
                handleBack={HandleBackBtn}
                handleSubmit={OnSubmitForm}
                handleSubmit2={OnSubmitForm2}
                handleClear={() => setClearData(true)}
                isLastOption={lastOption}
              />
            </form>
          </div>
        </div>
      </DashboardLayout>

      {saveDataState && (
        <SaveData feilds={["age", "race","pregnant","breastfeeding","menopausal"]} title={"Demographics"} />
      )}
      {importDataState && (
        <RenderData feilds={["age", "race","pregnant","breastfeeding","menopausal"]} title={"Demographics"} />
      )}
      {clearData && (
        <DeleteData
          onCancel={handleClearCancel}
          onConfirm={handleClearConfirm}
          feilds={["gender", "age", "race", "breastfeeding", "pregnant","menopausal"]}
        />
      )}
    </>
  );
}

export default ClinicalQuestion2;
