import React, { useState, useEffect, useRef } from 'react';
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../../common/helper";

const FirstTreatmentDropdown = ({onFirstTreatmentSelect}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedTreatment, setSelectedTreatment] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [treatments, setTreatments] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const dropdownRef = useRef(null);
    
    const apiUrl = STAGGING_BACKEND + "cancer/questionair/get-filtered-items/";

    useEffect(() => {
        const storedMechanism = JSON.parse(localStorage.getItem('selectedMechanism'));
        if (storedMechanism) {
          setSelectedTreatment(storedMechanism.value);
          setSearchTerm(storedMechanism.label);
        }
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (searchTerm && showDropdown) {
            const debounceTimeout = setTimeout(() => {
                setIsSearching(true);
                const requestOptions = {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "token " + localStorage.getItem("token"),
                    }}
                fetch(`${apiUrl}?filter=unique_treatments&query=${encodeURIComponent(searchTerm)}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        setTreatments(data.data);
                        setIsSearching(false);
                    })
                    .catch(error => {
                        console.error('Error fetching cities:', error);
                        setIsSearching(false);
                    });
            }, 300); // Debounce time

            return () => clearTimeout(debounceTimeout);
        } else {
            setTreatments([]);
        }
    }, [searchTerm, apiUrl, showDropdown]);

    const handleTreatmenSelect = (treatment) => {
        setSelectedTreatment(treatment.value);
        setSearchTerm('');
        setShowDropdown(false);
        onFirstTreatmentSelect(treatment);
        localStorage.setItem('selectedFirstTreatment', JSON.stringify(treatment)); 
    };
    console.log("First Treatment: ", selectedTreatment)

    return (
        <div ref={dropdownRef} style={{ position: 'relative', zIndex: 20 }}>
            <div
                onClick={() => setShowDropdown(!showDropdown)}
                style={{ cursor: 'pointer', padding: '5px', border: '1px solid #ccc' }}
            >
                {<input
                    type="text"
                    placeholder="Search Treatment"
                    value={selectedTreatment || searchTerm}
                    className='cityInput'
                    onChange={(e) => {
                        setSelectedTreatment(null)
                        setSearchTerm(e.target.value)}}
                    style={{ width: '100%', padding: '10px', margin: '0', boxSizing: 'border-box', backgroundColor:'white' }}
                />}
            </div>
            {showDropdown && (
                <div style={{
                    
                    width: '100%',
                    zIndex: 3,
                    maxHeight: '200px', // Fixed height
                    overflowY: 'auto', // Enable scrolling
                    backgroundColor: '#fff', // Background color for visibility
                    border: '1px solid #ccc', // Border for dropdown
                    borderTop: 'none' // Remove border top to blend with the input field
                }}>
                    {isSearching && <div>Searching...</div>}
                    <ul style={{ listStyle: 'none', padding: '0', margin: '0' }}>
                        {treatments.map(treatment => (
                            <li
                                key={treatment.id}
                                onClick={() => handleTreatmenSelect(treatment)}
                                style={{ padding: '10px', borderBottom: '1px solid #ccc', cursor: 'pointer' }}
                            >
                                {treatment.label}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FirstTreatmentDropdown;

