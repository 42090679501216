import React, { useState, useEffect, useRef } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { STAGGING_BACKEND, LOCAL_BACKEND } from "../../common/helper";
import SaveData from "../../components/Save_Data/Save_Data";
import RenderData from "../../components/Save_Data/Render_Data";
// import { Prev } from "react-bootstrap/esm/PageItem";
import { useDispatch, useSelector } from "react-redux";
import DeleteData from "../../components/Save_Data/Delete_Data";
import Select from "react-select";
import QuestionAirFormFooter from "../../components/questionAirFormFooter/questionAirFormFooter";
import QuestionAirTabs from "../../components/questionairTabs/questionairTabs";
import DashboardLayout from "../../components/dashboardLayout/dashboardLayout";
import { booleanOptions } from "../../libs/optionsHandling";
import { generateNamesArray, handleSwitchStatement } from "../../libs/Util";
import crossIcon from "../../images/cross.svg";
import { useLocation } from "react-router-dom";
import { check_stage_and_tumor_type } from "./utils/helper";
import { navigateToPreviousOption, navigateToNextOption, getPageOptionsFromIndices } from "../../libs/Util";
import Loader from "../../components/Loader/Loader";
import {
  tumorTypesSelective,
  HER2Options,
  dMMROptions,
  progesteroneReceptorOptions,
  estrogenReceptorOptions,
  questionAirOptionCards,
} from "../../libs/optionsHandling";

function ClinicalQuestion6() {
  const location = useLocation();

  const [lastOption, setLastOption] = useState(false);
  const [clearData, setClearData] = useState(false);
  const [pdl1, setPdl1] = useState(false);
  const [cancerCellsPdl, setCancerCellsPdl] = useState("");
  const [cpc_tps, setCpcTpc] = useState("");
  const [msi, setMsi] = useState(false);
  const [tmb, setTmb] = useState(false);
  const [tumorType, setTumorType] = useState();
  const [showBreastCancerFields, setShowBreastCancerFields] = useState(false);
  const [ki67, setKi67] = useState(
    localStorage.getItem("Cancer_Characteristicsis_Ki67") || ""
  );
  const [HER2, setHER2] = useState("");
  const [progesteroneReceptorPositive, setProgesteroneReceptorPositive] =
    useState("");
  const [estrogenReceptorPositive, setEstrogenReceptorPositive] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [recombinationDeficiency, setRecombinationDeficiency] = useState(false);
  const [importDataState, setImportDataState] = useState(false);
  const [saveDataState, setSaveDataState] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropDown, setShowDropDown] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [dMMR, setdMMR] = useState(null)

  const data = ["Bone", "Skin", "Liver", "Lungs", "Colon", "Rectum", "Esgophagus", "Brain"];
  const filteredData = data.filter(item => item.toLowerCase().includes(searchTerm.toLowerCase()));
  const [cancerLocatedShow, setCancerLocatedShow] = useState(false)

  const cancerCellsPdlRef = useRef();

  useEffect(() => {
    const questionVisibility = check_stage_and_tumor_type() ? setCancerLocatedShow(true) : setCancerLocatedShow(false)
  }, []);

  const navigate = useNavigate();
  // const update = useForceUpdate();
  const selector = useSelector((state) => state.options);

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("mode");
  const page = urlParams.get("page");
  const mode = urlParams.get("mode");
  const nullValue = { label: "choose below", value: "" };
  const savedFilter = urlParams.get("checkByCustomNctIds");
  const optionsToShow = new URLSearchParams(location.search).get(
    "optionsToShow"
  );
  const pageOptions = getPageOptionsFromIndices(optionsToShow);
  let names = generateNamesArray(
    mode,
    pageOptions,
    savedFilter,
    page,
    optionsToShow,
    optionsToShow
  );

  const OnSubmitForm = async () => {
    try {
      setIsLoading(true);
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        pd_l1: pdl1.value,
        msi_high: msi.value,
        tmb_high: tmb.value,
        ki67: ki67,
        HER2: HER2?.value,
        progesterone_receptor_positive: progesteroneReceptorPositive?.value,
        estrogen_receptor_positive: estrogenReceptorPositive?.value,
        recombination_deficiency: recombinationDeficiency.value,
        cancer_cells_pdl: cancerCellsPdlRef?.current?.value,
        cancer_mismatch_repair_deficient: dMMR?.value,
        cancer_location : selectedItems
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();

      if (mode != "full_options") {
        navigateToNextOption(navigate, "5", mode, optionsToShow);
      } else {
        navigate(
          `/clinical-question7?mode=full_options${page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
          }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  function HandleBackBtn() {
    if (mode != "full_options") {
      navigateToPreviousOption(navigate, "5", mode, optionsToShow);
    } else {
      navigate(
        `/clinical-question5?mode=full_options${page === "ccTreatmentGuide" ? "&page=ccTreatmentGuide" : ""
        }${savedFilter === "true" ? "&checkByCustomNctIds=true" : ""}`
      );
    }
  }
  useEffect(() => {
    const cancerType = localStorage.getItem('Cancer_Type_what_cancer');
    if (cancerType === 'Breast Cancer') {
      setShowBreastCancerFields(true);
    }
  }, []);

  useEffect(() => {
    let array = names.map((item) => {
      return item.text;
    });
    Check_Last_Index(array);
    setImportDataState(true);
    let select_array = [
      {
        title: "Cancer_Characteristicsis_cancer_pdl1",
        options: booleanOptions,
      },
      { title: "Cancer_Characteristicsis_msi_high", options: booleanOptions },
      { title: "Cancer_Characteristicsis_tmb_high", options: booleanOptions },
      {
        title: "Cancer_Characteristicsis_actionable_mutation",
        options: booleanOptions,
      },
      {
        title: "Cancer_Characteristicsis_measurable_cancer",
        options: booleanOptions,
      },
      {
        title: "Cancer_Characteristicsis_estrogenReceptorPositive",
        options: estrogenReceptorOptions,
      },
      {
        title: "Cancer_Characteristicsis_progesteroneReceptorPositive",
        options: progesteroneReceptorOptions,
      },
      { title: "Cancer_Characteristicsis_HER2", options: HER2Options },
      { title: "Cancer_Characteristicsis_ki67", options: null },
      { title: "Cancer_Characteristicsis_homolgous", options: booleanOptions },
    ];
    // Import_Save_Data("Cancer_Type_estrogenReceptorPositive", estrogenReceptorOptions);
    // Import_Save_Data("Cancer_Type_progesteroneReceptorPositive", progesteroneReceptorOptions);
    // Import_Save_Data("Cancer_Type_HER2", HER2Options);  
    // Import_Save_Data("Cancer_Type_ki67", null);
    select_array.forEach((item) => {
      Import_Save_Data(item.title, item.options);
    });

  }, []);

  function Import_Save_Data(title, options) {
    const genderOpt = window.localStorage.getItem(title);


    if (genderOpt) {
      const result = options.find((option) => option.value == genderOpt);
      if (result) {
        // console.log("typee", result, title);
        switch (title) {
          case "Cancer_Characteristicsis_cancer_pdl1":
            setPdl1(result);
            break;
          case "Cancer_Characteristicsis_msi_high":
            setMsi(result);
            break;
          case "Cancer_Characteristicsis_tmb_high":
            setTmb(result);
            break;
          case "Cancer_Characteristicsis_homolgous":
            setRecombinationDeficiency(result);
            break;
          case "Cancer_Characteristicsis_estrogenReceptorPositive":
            setEstrogenReceptorPositive(result);
            break;
          case "Cancer_Characteristicsis_progesteroneReceptorPositive":
            setProgesteroneReceptorPositive(result);
            break;
          case "Cancer_Characteristicsis_HER2":
            setHER2(result);
            break;
          case "Cancer_Characteristicsis_Ki67":
            setKi67(result);
            break;
          // case 'Cancer_Characteristicsis_actionable_mutation':
          // setActionableMutation(result);
          // break;
          // case 'Cancer_Characteristicsis_measurable_cancer':
          // setMetastases(result);
          // break;

          default:
            break;
        }
      }
    }
  }

  useEffect(() => {
    setSaveDataState(true);
    Save_Select_Data();
  }, [
    recombinationDeficiency,
    cancerCellsPdl,
    tmb,
    msi,
    cpc_tps,
    pdl1,
    estrogenReceptorPositive,
    progesteroneReceptorPositive,
    HER2,
  ]);

  function Save_Select_Data() {
    if (pdl1)
      window.localStorage.setItem(
        `Cancer_Characteristicsis_cancer_pdl1`,
        pdl1.value
      );
    if (msi)
      window.localStorage.setItem(
        `Cancer_Characteristicsis_msi_high`,
        msi.value
      );
    if (tmb)
      window.localStorage.setItem(
        `Cancer_Characteristicsis_tmb_high`,
        tmb.value
      );
    if (recombinationDeficiency)
      window.localStorage.setItem(
        `Cancer_Characteristicsis_homolgous`,
        recombinationDeficiency.value
      );
    if (estrogenReceptorPositive)
      window.localStorage.setItem(
        `Cancer_Characteristicsis_estrogenReceptorPositive`,
        estrogenReceptorPositive.value
      );

    if (progesteroneReceptorPositive)
      window.localStorage.setItem(
        `Cancer_Characteristicsis_progesteroneReceptorPositive`,
        progesteroneReceptorPositive.value
      );
    if (HER2)
      window.localStorage.setItem(`Cancer_Characteristicsis_HER2`, HER2.value);


    if (ki67 !== null)
      window.localStorage.setItem(`Cancer_Characteristicsis_Ki67`, ki67);
    // if(actionable_mutation)
    // window.localStorage.setItem(`Cancer_Characteristicsis_actionable_mutation`,actionable_mutation.value);
    // if(metastases)
    // window.localStorage.setItem(`Cancer_Characteristicsis_measurable_cancer`,metastases.value);
  }

  function Check_Last_Index(array) {
    const lastIndex = array[array.length - 1];
    console.log("last item is", lastIndex);
    if (lastIndex == "Cancer Characteristics") setLastOption(true);
  }

  const OnSubmitForm2 = async () => {
    try {
      setIsLoading(true);
      const url = STAGGING_BACKEND + "cancer/questionair/cancer_type/";
      const payload = {
        tumor_type: tumorType?.value,
        pd_l1: pdl1.value,
        msi_high: msi.value,
        tmb_high: tmb.value,
        recombination_deficiency: recombinationDeficiency,
        cancer_cells_pdl: cancerCellsPdlRef?.current?.value,
        ki67: ki67,
        HER2: HER2?.value,
        progesterone_receptor_positive: progesteroneReceptorPositive?.value,
        estrogen_receptor_positive: estrogenReceptorPositive?.value,
        cancer_mismatch_repair_deficient: dMMR?.value,
        cancer_location : selectedItems
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + localStorage.getItem("token"),
        },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();

      console.log(responseData);
      navigate(
        page === "ccTreatmentGuide"
          ? "/cc-treatment-plans"
          : `/stats-drugs${savedFilter === "true" ? "?checkByCustomNctIds=true" : ""
          }`
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearCancel = () => {
    setClearData(false);
  };

  const handleClearConfirm = () => {
    setTmb(nullValue);
    setMsi(nullValue);
    setPdl1(nullValue);
    setRecombinationDeficiency(nullValue);
    setTmb(nullValue);
    setHER2(nullValue);
    setEstrogenReceptorPositive(nullValue);
    setProgesteroneReceptorPositive(nullValue);
    setKi67(nullValue);

    setClearData(false);
  };
  useEffect(() => {
    localStorage.setItem("Cancer_Characteristicsis_Ki67", ki67);
  }, [ki67]);

  function handleListItemClick(item) {
    let updatedSelectedItems;
    if (selectedItems.includes(item)) {
      updatedSelectedItems = selectedItems.filter(selectedItem => selectedItem !== item);
    } else {
      updatedSelectedItems = [...selectedItems, item];
    }
    setSelectedItems(updatedSelectedItems);
    window.localStorage.setItem("Cancer_Characteristicsis_cancer_located_options", JSON.stringify(updatedSelectedItems));
  }

  function onRemoveTag(itemToRemove) {
    const newSelectedItems = selectedItems.filter(item => item !== itemToRemove);
    setSelectedItems(newSelectedItems);
    window.localStorage.setItem("Cancer_Characteristicsis_cancer_located_options", JSON.stringify(newSelectedItems));
  }

  return (
    <>
      {isLoading && <Loader />}
      <DashboardLayout>
        <div className="clinical-question-main-area mt-4">
          <h1 className="text-center site-heading">
            {page === "ccTreatmentGuide"
              ? "CC Treatment Guide"
              : "Clinical Trial Finder"}
          </h1>
          <QuestionAirTabs names={names} currentTab="5" />
          <div className="activeSection-wrapper">
            <div className="question-form-wrapper">
              <form action="">
                <h2>Cancer Characteristics</h2>
                <p>Please input the following data</p>
                <div className="form_container_sec">
                  <label
                    htmlFor=""
                    title="Some cancer make a protein called PD-L1 to prevent a patient's immune system from killing it.  Often times, patients' cancers are tested to determine if they make PD-L1 or not.  Any cancers that make PD-L1 in 1% or more of the cells is considered positive.  Please select (yes) if your cancer makes PD-L1 at 1% or more."
                  >
                    Is your cancer PD-L1 positive?{" "}
                  </label>

                  <Select
                    options={booleanOptions}
                    value={pdl1}
                    className="select_search"
                    id="is_cancer_pdl1"
                    onChange={(event) => {
                      setPdl1(event);
                    }}
                  />

                  {pdl1.value == "1" ? (
                    <>
                      <label title="Generally, the amount of PD-L1 positivity will be present in the pathology report and be given as a CPS score or a percentage.">
                        specify the percentage of cancer cells that make PD-L1?
                      </label>
                      <input
                        type="text"
                        name="userName"
                        className="signup-box-input loginfields"
                        placeholder="Enter % of cancer cells"
                        id="cps_tps_score"
                        ref={cancerCellsPdlRef}
                        onChange={(event) =>
                          setCancerCellsPdl(event.target.value)
                        }
                      />{" "}
                    </>
                  ) : null}

                  <label
                    htmlFor=""
                    title="We often test cancer cells to determine if they have a high level of DNA instability using a test referred to as 'microsatellite instability'.  If you have a test for this please indicate if your cancer was microsatellite instability high or not."
                  >
                    Does your cancer have high microsatellite instability?
                    (MSI-high)
                  </label>
                  <Select
                    options={booleanOptions}
                    value={msi}
                    className="select_search"
                    id="msi_high"
                    onChange={(event) => {
                      setMsi(event);
                    }}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 1000
                      })
                    }}
                  />

                  <label
                    htmlFor=""
                    title=" We often test cancer cells to determine if they have a high level of mutations, referred to as tumor mutation burden.  Please indicate if your cancer has high tumor mutation burden, as depicted in your molecular profiling report. "
                  >
                    Does your cancer have high tumor mutation burden (TMB-high)?{" "}
                  </label>

                  <Select
                    options={booleanOptions}
                    value={tmb}
                    className="select_search"
                    name="tmb_high"
                    id="tmb_high"
                    onChange={(event) => {
                      setTmb(event);
                    }}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 1000
                      })
                    }}
                  />

                  <label
                    htmlFor=""
                    title=" We often test cancer cells to determine if they have a high level of mutations, referred to as tumor mutation burden.  Please indicate if your cancer has high tumor mutation burden, as depicted in your molecular profiling report. "
                  >
                    Does your cancer have homologous recombination deficiency?
                  </label>

                  <Select
                    options={booleanOptions}
                    value={recombinationDeficiency}
                    className="select_search"
                    id="homolgous"
                    name="homolgous"
                    onChange={(event) => {
                      setRecombinationDeficiency(event);
                    }}
                    styles={{
                      menu: (base) => ({
                        ...base,
                        zIndex: 1000
                      })
                    }}
                  />
                  {showBreastCancerFields && (
                    <>
                      <label htmlFor="">Is your cancer estrogen receptor positive? </label>
                      <Select
                        options={estrogenReceptorOptions}
                        value={estrogenReceptorPositive}
                        className="select_search"
                        id="subtype3"
                        onChange={(event) => {
                          setEstrogenReceptorPositive(event);
                        }}
                      />
                      <>
                        <label htmlFor="">
                          Is your cancer progesterone receptor positive?{' '}
                        </label>
                        <Select
                          options={progesteroneReceptorOptions}
                          value={progesteroneReceptorPositive}
                          className="select_search"
                          id="subtype4"
                          onChange={(event) => {
                            setProgesteroneReceptorPositive(event);
                          }}
                        />
                      </>
                      <>
                        <label htmlFor="">What is your cancer's HER2 expression level </label>
                        <Select
                          options={HER2Options}
                          value={HER2}
                          className="select_search"
                          id="subtype5"
                          onChange={(event) => {
                            setHER2(event);
                          }}
                          styles={{
                            menu: (base) => ({
                              ...base,
                              zIndex: 1000
                            })
                          }}
                        />
                      </>
                      <>
                        <label htmlFor="">What is your ki67 level? </label>
                        <input
                          type="number"
                          min={0}
                          max={100}
                          value={ki67}
                          onChange={(e) => setKi67(e.target.value)}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onInput={(event) => {
                            if (event.target.value < 0) event.target.value = 0;
                            if (event.target.value > 100) event.target.value = 100;
                          }}
                          className="signup-box-input loginfields"
                          placeholder="Enter ki67 level (0-100)"
                          id="Ki67"
                        />
                      </>
                    </>
                  )}

                  <>
                    <label htmlFor="">Is your cancer mismatch repair deficient(dMMR)? </label>
                    <Select
                      options={dMMROptions}
                      value={dMMR}
                      className="select_search"
                      id="subtype5"
                      onChange={(event) => {
                        setdMMR(event);
                      }}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          zIndex: 1000
                        })
                      }}
                    />
                  </>
                  {cancerLocatedShow && (
                    <>
                      <label htmlFor="">Where is your cancer located? (Please select all that apply)</label>
                      <br />
                      <section className="multiselect-section">
                        <div className="custom-multiselect-container">
                          <div
                            className="multiselect-label-area"
                            style={{ padding: "0px" }}
                            onClick={() => setShowDropDown(true)}
                          >
                            <input
                              type="text"
                              placeholder="Select Options"
                              value={searchTerm}
                              className="mutationSearchInput"
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onClick={() => setShowDropDown(true)}
                            />
                          </div>
                          {showDropDown && (
                            <div className="mutliselect-options-wrap">
                              <ul id="multiSelectList">
                                {filteredData.map((item, index) => (
                                  <li
                                    key={index}
                                    value={item}
                                    className={selectedItems.includes(item) ? "selected_li" : ""}
                                    onClick={() => handleListItemClick(item)}
                                  >
                                    {item}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div className="select-tags-container" id="select-tags-container">
                          <h6 style={{ margin: "0" }} className="text-center">Selected Options</h6>
                          {Array.isArray(selectedItems) && selectedItems.map((item, index) => (
                            <div key={index} className="selected-tag">
                              {item}
                              <img
                                src={crossIcon}
                                className="selected-tag-cross"
                                onClick={() => onRemoveTag(item)}
                                alt="Remove tag"
                              />
                            </div>
                          ))}
                        </div>
                      </section>
                    </>
                  )}

                </div>
                <QuestionAirFormFooter
                  handleBack={HandleBackBtn}
                  handleSubmit={OnSubmitForm}
                  handleSubmit2={OnSubmitForm2}
                  handleClear={() => setClearData(true)}
                  isLastOption={lastOption}
                />
              </form>
            </div>
          </div>
        </div>
      </DashboardLayout>

      {saveDataState && (
        <SaveData feilds={["cps_tps_score", "subtype3", "subtype4", "subtype5", "Ki67"]} title={"Cancer_Characteristics"} />
      )}
      {importDataState && (
        <RenderData
          feilds={["cps_tps_score", "subtype3", "subtype4", "subtype5", "Ki67"]}
          title={"Cancer_Characteristics"}
        />
      )}
      {clearData && (
        <DeleteData
          onCancel={handleClearCancel}
          onConfirm={handleClearConfirm}
          feilds={[
            "is_cancer_pdl1",
            "cps_tps_score",
            "msi_high",
            "actionable_mutation",
            "tmb_high",
            "homolgous",
            "subtype3",
            "subtype4",
            "subtype5",
            "Ki67"
          ]}
        />
      )}
    </>
  );
}

export default ClinicalQuestion6;
