import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import heart from '../../../images/heart.svg'
import download from '../../../images/download.svg'
import { LOCAL_BACKEND, STAGGING_BACKEND } from "../../../common/helper";
import Loader from "../../../components/Loader/Loader";

const TrialsByTherapy = ({seeDetails}) => {
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const savedFilter = urlParams.get("checkByCustomNctIds");

  const NctIds = JSON.parse(window.sessionStorage.getItem("checkByCustomNctIds"));
  console.log(NctIds)


  useEffect(async () => {
    const filter = "unique_therapies"
    let url = null;
    (savedFilter != "true" ? url = STAGGING_BACKEND + "cancer/analytics/?filter=" + filter:
     url = STAGGING_BACKEND + "cancer/analytics/?filter=" + filter  + "&by_save="+ NctIds.saved+"&custom_nct_ids="+NctIds.nct_ids);

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token")
      },
      // body: JSON.stringify(payload)
    };
    setIsLoading(true);
    const response = await fetch(url, requestOptions);
    const responseData = await response.json();
    console.log(responseData)
    setData(responseData.data);
    setIsLoading(false);
  }, [])

  const downloadAll = async () => {
    const filter = "unique_therapies"
    let url = null;
    (savedFilter != "true" ? url = STAGGING_BACKEND + "cancer/analytics/?filter=" + filter + "&export=xls" :
     url = STAGGING_BACKEND + "cancer/analytics/?filter=" + filter + "&export=xls" + "&by_save="+NctIds.saved+"&custom_nct_ids="+NctIds.nct_ids);

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + localStorage.getItem("token")
      },
      responseType: 'blob',
      // body: JSON.stringify(payload)
    };

    const response = await fetch(url, requestOptions);
    const responseData = await response.blob()

    const blob = new Blob([responseData]);
    const blob_url = window.URL.createObjectURL(blob);


    const link = document.createElement('a');
    link.href = blob_url;
    link.setAttribute('download', 'unique_line_of_therapies.xls'); // Set the desired file name

    // Append the link to the document body and click it to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link and revoking the URL object
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);


  }

  function HandleBackBtn() {
    document
      .querySelector(".analytics-drug-table-container")
      .classList.remove("active");
    document.querySelector("#analytics-Container").classList.remove("hide");
    
  }

  function HandleSeeDetail() {
    document.querySelector('#numberofUniqueMechanism').style.display = "block"
    document.querySelector('#table').style.display = "none"
  }

  return (
    <>
    {isLoading && <Loader/>}
    <div className="d-flex justify-content-end mb-3">
        {/* <button className="blue-button" style={{ marginRight: '10px' }}>Save All</button> */}
        <button className="blue-button" style={{ marginRight: '10px' }} onClick={()=>{
          downloadAll();
        }}>Download</button>
        <button className="blue-button" onClick={HandleBackBtn}>Back</button>
      </div>
      <div style={{ overflowX: "auto", overflowY: "auto", maxHeight: "370px" }} className="drugstatusTable-wrapper">
        <Table hover className="drugsTable" >
          <thead>
            <tr>
              <th>S NO</th>
              <th>LINE OF THERAPY</th>
              {/* <th>SPONCOR</th> */}
              <th>NO OF TRIALS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((value, key) => {
              return <tr>
                <td>{key + 1}</td>
                <td className="drugName">{value.analytics_lof}</td>
                {/* <td>
                  <p>{value.sponsor}</p>
                </td> */}
                <td>{value.trials}</td>

                <td>
                  <button className="blue-button" id="see-details" onClick={()=>{
                    seeDetails({key : value.analytics_lof, filter:"uniqueTherapies"})
                    }}>
                    See Details
                  </button>

                  {/* <img
                    src={download}
                    className="drugstableIcon"
                    alt=""
                  />

                  <img
                    src={heart}
                    className="drugstableIcon"
                    alt=""
                  /> */}
                </td>
              </tr>
            })}

          </tbody>
        </Table>
      </div>
    </>
  );
}
export default TrialsByTherapy;
