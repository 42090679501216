import React from 'react';
import styles from '../../css/pagination.module.css';

const Pagination = ({ currentPage, totalPages, onPageChange, totalRecords }) => {


  const maxPagesToShow = 5;
  let startPage, endPage;

  if (totalPages <= maxPagesToShow) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (currentPage + Math.floor(maxPagesToShow / 2) >= totalPages) {
      startPage = totalPages - (maxPagesToShow - 1);
      endPage = totalPages;
    } else {
      startPage = currentPage - Math.floor(maxPagesToShow / 2);
      endPage = currentPage + Math.floor(maxPagesToShow / 2);
    }
  }

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className={styles.pagination}>
        <li className={styles.pageItem} style={{padding: '0!important'}}>
          <button
            onClick={() => onPageChange(currentPage - 1)}
            className={`${styles.pageLink} ${currentPage === 1 ? styles.pageLinkDisabled : ''}`}
            disabled={currentPage === 1}
          >
            Previous
          </button>
        </li>
        {pageNumbers.map(number => (
          <li key={number} className={styles.pageItem}  style={{padding: '0px!important'}}>
            <button
              onClick={() => onPageChange(number)}
              className={`${styles.pageLink} ${currentPage === number ? styles.activePageLink : ''}`}
            >
              {number}
            </button>
          </li>
        ))}
        <li className={styles.pageItem}>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            className={`${styles.pageLink} ${currentPage === totalPages ? styles.pageLinkDisabled : ''}`}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </li>
      </ul>
      <p className={styles.recordText}>Total Records: <b>{totalRecords}</b></p>
    </nav>
  );
};

export default Pagination;
